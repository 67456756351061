import React, {
  useCallback, useContext, useEffect, useState,
} from "react";

import GlobalContext from "@/contexts/Global/GlobalContext";
import { DeviceTypeEnum } from "@/globalTypes";
import { browserDetect, browserInfo, detectDeviceType } from "@/utils/env";
import { capitalize } from "@/utils/stringUtils";
import { instructionList } from "@components/chat/mediaCheck/FixMediaAccess/constants";
import {
  changeInstruction,
  generateKey,
  toggleClassname,
} from "@components/chat/mediaCheck/FixMediaAccess/utils";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import "./styles.scss";

// todo: refactor
const FixMediaAccess = () => {
  const [instruction, setInstruction] = useState<JSX.Element>();

  const {
    isAlertShown,
    setIsAlertShown,
  } = useContext(GlobalContext);

  useEffect(() => {
    setIsAlertShown(false);

    return () => {
      setIsAlertShown(true);
    };
  }, [isAlertShown, setIsAlertShown]);

  const setInstructionHandler = useCallback(
    (instruction: JSX.Element) => {
      setInstruction(instruction);
    },
    [setInstruction],
  );

  useEffect(() => {
    const currentOS = (detectDeviceType() === DeviceTypeEnum.Mobile && browserInfo?.os) || "";

    const instructionForCurrentDevice = instructionList.find((item) =>
      (
        item.deviceType === detectDeviceType()
        && item.browser === browserDetect()
        && item.OS === currentOS
      ));

    if (instructionForCurrentDevice) {
      changeInstruction(setInstructionHandler, detectDeviceType(), browserDetect(), currentOS);
    }

    return () =>
      setIsAlertShown(true);
  }, []);

  return (
    <div className="page-width">
      <div className="device-info__page">
        <div className="article">
          <h1>Как разрешить доступ к&nbsp;камере и&nbsp;микрофону</h1>
          <div className="device-info" data-device-info="">
            {browserInfo && (
              <div className="device-info__description">
                Мы определили, что вы используете
                {" "}
                <b>{capitalize(browserDetect())}</b>
                {" "}
                на
                устройстве
                {" "}
                <b>{browserInfo.os}</b>
                .
              </div>
            )}
            <div className="device-info__harmonic">
              <div
                className="device-info__harmonic-head"
                onClick={() =>
                  toggleClassname("[data-device-info]", "open")}
              >
                {instruction ? "Выбрать устройство и браузер" : "Список поддерживаемых браузеров"}
                <Icon type={IconTypeEnum.Dropdown} size={IconSizeEnum.Size16} />
              </div>
              <div className="device-info__harmonic-body">
                {instructionList.map((item, index, array) =>
                  (
                    <React.Fragment key={generateKey(item)}>
                      {(index === 0 || (array[index - 1].group !== item.group))
                        && (
                          <div className="device-info__group-title">
                            {item.group}
                            :
                          </div>
                        )}
                      <div
                        className="device-info__item"
                        data-key={generateKey(item)}
                        onClick={() =>
                          instruction
                          && changeInstruction(setInstructionHandler, item.deviceType, item.browser, item.OS)}
                      >
                        {item.name}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>

          {instruction}
          <p>
            Если видеосвязь по-прежнему не&nbsp;работает, напишите в&nbsp;нашу поддержку
            на&nbsp;почту
            {" "}
            <a href="mailto:support@lunaro.ru">support@lunaro.ru</a>
            {" "}
            или в мессенджеры
            {" "}
            <a href="https://t.me/lunaro_lunaro_bot" target="_blank" rel="noreferrer">
              Телеграм
            </a>
            ,
            {" "}
            <a
              href="https://api.whatsapp.com/send/?phone=79581977977&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noreferrer"
            >
              WhatsApp
            </a>
            . Отвечаем быстро.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FixMediaAccess;
