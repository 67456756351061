import { instructionList } from "@components/chat/mediaCheck/FixMediaAccess/constants";

/**
 * Just toggle class
 * @param selectors
 * @param className
 */
export function toggleClassname(selectors: string, className: string) {
  document.querySelector(selectors)?.classList.toggle(className);
}

/**
 * Generates unique key by device params
 * @param item
 */
export function generateKey(item: { browser: any; OS: any; deviceType: any; }) {
  return `${item.deviceType}${item.browser}${item.OS}`.replace(/ /g, "");
}

/**
 * Marks selected menu item by key (data-key value)
 * @param key
 */
export function markInstructionMenuItem(key: string) {
  const currentItem = document.querySelector(`[data-key=${key}]`);

  if (currentItem) {
    document.querySelector("[data-key].selected")?.classList.remove("selected");
    currentItem.classList.add("selected");
  }
}

/**
 * Change displayed instruction
 * @param setInstructionHandler
 * @param deviceType
 * @param browser
 * @param OS
 */
export function changeInstruction(
  setInstructionHandler: (instruction: JSX.Element) => void,
  deviceType: string,
  browser: string,
  OS = "",
) {
  const result = instructionList.find((item) =>
    (
      item.deviceType === deviceType
      && item.browser === browser
      && item.OS === OS
    ));

  if (result) {
    markInstructionMenuItem(generateKey(result));
    return setInstructionHandler(result.instruction);
  }
}
