import React from "react";

import { BrowserNameEnum, DeviceTypeEnum, OSNameEnum } from "@/globalTypes";

export const instructionList = [
  {
    group: "iPhone",
    name: "Safari",
    browser: BrowserNameEnum.Safari,
    OS: OSNameEnum.IOS,
    deviceType: DeviceTypeEnum.Mobile,
    instruction:
  <ol>
    <li>
      <p>Перейдите в «Настройки» iOS.</p>
      <img
        alt=""
        src="/chat/instruction/iphone_settings.jpg"
        srcSet={`/chat/instruction/iphone_settings.jpg 1x,
              /chat/instruction/iphone_settings@2x.jpg 2x,
              /chat/instruction/iphone_settings@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>Прокрутите страницу настроек вниз и нажмите на «Safari».</p>
      <img
        alt=""
        src="/chat/instruction/safari_settings.jpg"
        srcSet={`/chat/instruction/safari_settings.jpg 1x,
              /chat/instruction/safari_settings@2x.jpg 2x,
              /chat/instruction/safari_settings@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>В списке выберите пункт «Камера».</p>
      <img
        alt=""
        src="/chat/instruction/safari_camera.jpg"
        srcSet={`/chat/instruction/safari_camera.jpg 1x,
              /chat/instruction/safari_camera@2x.jpg 2x,
              /chat/instruction/safari_camera@3x.jpg 3x`}
      />
      <p>И установите галочку напротив «Спросить».</p>
      <img
        alt=""
        src="/chat/instruction/safari_camera_access.jpg"
        srcSet={`/chat/instruction/safari_camera_access.jpg 1x,
              /chat/instruction/safari_camera_access@2x.jpg 2x,
              /chat/instruction/safari_camera_access@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>Повторите то же самое для микрофона.</p>
      <img
        alt=""
        src="/chat/instruction/safari_microphone.jpg"
        srcSet={`/chat/instruction/safari_microphone.jpg 1x,
              /chat/instruction/safari_microphone@2x.jpg 2x,
              /chat/instruction/safari_microphone@3x.jpg 3x`}
      />
      <br />
      <img
        alt=""
        src="/chat/instruction/safari_microphone_access.jpg"
        srcSet={`/chat/instruction/safari_microphone_access.jpg 1x,
              /chat/instruction/safari_microphone_access@2x.jpg 2x,
              /chat/instruction/safari_microphone_access@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>Вернитесь на наш сайт на страницу проверки видеосвязи.</p>
      <img
        alt=""
        src="/chat/instruction/lunaro_check_media.jpg"
        srcSet={`/chat/instruction/lunaro_check_media.jpg 1x,
              /chat/instruction/lunaro_check_media@2x.jpg 2x,
              /chat/instruction/lunaro_check_media@3x.jpg 3x`}
      />
    </li>
    <li>Перезагрузите страницу, чтобы настройки вступили в силу.</li>
    <li>
      <p>Во всплывающем окне нажмите «Разрешить» для камеры и микрофона.</p>
      <img
        alt=""
        src="/chat/instruction/safari_media_access.jpg"
        srcSet={`/chat/instruction/safari_media_access.jpg 1x,
              /chat/instruction/safari_media_access@2x.jpg 2x,
              /chat/instruction/safari_media_access@3x.jpg 3x`}
      />
    </li>
  </ol>,
  }, {
    group: "iPhone",
    name: "Google Chrome",
    browser: BrowserNameEnum.Chrome,
    OS: OSNameEnum.IOS,
    deviceType: DeviceTypeEnum.Mobile,
    instruction:
  <ol>
    <li>
      <p>Перейдите в «Настройки» iOS.</p>
      <img
        alt=""
        src="/chat/instruction/iphone_settings.jpg"
        srcSet={`/chat/instruction/iphone_settings.jpg 1x,
              /chat/instruction/iphone_settings@2x.jpg 2x,
              /chat/instruction/iphone_settings@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>Прокрутите страницу настроек вниз и нажмите на «Chrome».</p>
      <img
        alt=""
        src="/chat/instruction/iphone_chrome.jpg"
        srcSet={`/chat/instruction/iphone_chrome.jpg 1x,
              /chat/instruction/iphone_chrome@2x.jpg 2x,
              /chat/instruction/iphone_chrome@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>В пунктах «Камера» и «Микрофон» разрешите доступ, нажав на переключатель.</p>
      <img
        alt=""
        src="/chat/instruction/iphone_chrome_settings.jpg"
        srcSet={`/chat/instruction/iphone_chrome_settings.jpg 1x,
              /chat/instruction/iphone_chrome_settings@2x.jpg 2x,
              /chat/instruction/iphone_chrome_settings@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>Вернитесь на наш сайт на страницу проверки видеосвязи.</p>
      <img
        alt=""
        src="/chat/instruction/lunaro_check_media.jpg"
        srcSet={`/chat/instruction/lunaro_check_media.jpg 1x,
              /chat/instruction/lunaro_check_media@2x.jpg 2x,
              /chat/instruction/lunaro_check_media@3x.jpg 3x`}
      />
    </li>
    <li>Перезагрузите страницу, чтобы настройки вступили в силу.</li>
    <li>
      <p>Во всплывающем окне нажмите «Разрешить» для камеры и микрофона.</p>
      <img
        alt=""
        src="/chat/instruction/safari_media_access.jpg"
        srcSet={`/chat/instruction/safari_media_access.jpg 1x,
              /chat/instruction/safari_media_access@2x.jpg 2x,
              /chat/instruction/safari_media_access@3x.jpg 3x`}
      />
    </li>
  </ol>,
  }, {
    group: "Android",
    name: "Яндекс.Браузер",
    browser: BrowserNameEnum.Yandex,
    OS: "",
    deviceType: DeviceTypeEnum.Mobile,
    instruction:
  <ol>
    <li>Перейдите в «Настройки» и в списке нажмите на «Яндекс».</li>
    <li>В пунктах «Камера» и «Микрофон» разрешите доступ, нажав на переключатель.</li>
    <li>
      <p>Вернитесь на наш сайт на страницу проверки видеосвязи.</p>
      <img
        alt=""
        src="/chat/instruction/lunaro_check_media.jpg"
        srcSet={`/chat/instruction/lunaro_check_media.jpg 1x,
              /chat/instruction/lunaro_check_media@2x.jpg 2x,
              /chat/instruction/lunaro_check_media@3x.jpg 3x`}
      />
    </li>
    <li>Перезагрузите страницу, чтобы настройки вступили в силу.</li>
    <li>
      <p>Во всплывающем окне нажмите «Разрешить» для камеры и микрофона.</p>
      <img
        alt=""
        src="/chat/instruction/safari_media_access.jpg"
        srcSet={`/chat/instruction/safari_media_access.jpg 1x,
              /chat/instruction/safari_media_access@2x.jpg 2x,
              /chat/instruction/safari_media_access@3x.jpg 3x`}
      />
    </li>
  </ol>,
  }, {
    group: "Android",
    name: "Google Chrome",
    browser: BrowserNameEnum.Chrome,
    OS: OSNameEnum.Android,
    deviceType: DeviceTypeEnum.Mobile,
    instruction:
  <ol>
    <li>
      <p>Откройте браузер, нажмите на значок меню (три точки, справа, вверху) и выберите «Настройки».</p>
      <img
        alt=""
        src="/chat/instruction/android_chrome.jpg"
        srcSet={`/chat/instruction/android_chrome.jpg 1x,
              /chat/instruction/android_chrome@2x.jpg 2x,
              /chat/instruction/android_chrome@3x.jpg 3x`}
      />
      <br />
      <img
        alt=""
        src="/chat/instruction/android_chrome_settings.jpg"
        srcSet={`/chat/instruction/android_chrome_settings.jpg 1x,
              /chat/instruction/android_chrome_settings@2x.jpg 2x,
              /chat/instruction/android_chrome_settings@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>В разделе «Дополнительные» выберите «Настройки сайтов».</p>
      <img
        alt=""
        src="/chat/instruction/android_chrome_site_settings.jpg"
        srcSet={`/chat/instruction/android_chrome_site_settings.jpg 1x,
              /chat/instruction/android_chrome_site_settings@2x.jpg 2x,
              /chat/instruction/android_chrome_site_settings@3x.jpg 3x`}
      />
      <img
        alt=""
        src="/chat/instruction/android_chrome_site_settings_microphone.jpg"
        srcSet={`/chat/instruction/android_chrome_site_settings_microphone.jpg 1x,
              /chat/instruction/android_chrome_site_settings_microphone@2x.jpg 2x,
              /chat/instruction/android_chrome_site_settings_microphone@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>
        В пункте «Камера» нажмите на переключатель справа. У вас должна появиться надпись «Запрашивать разрешение
        на доступ к камере».
      </p>
      <img
        alt=""
        src="/chat/instruction/android_chrome_camera_request_settings.jpg"
        srcSet={`/chat/instruction/android_chrome_camera_request_settings.jpg 1x,
              /chat/instruction/android_chrome_camera_request_settings@2x.jpg 2x,
              /chat/instruction/android_chrome_camera_request_settings@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>Сделайте то же самое в пункте «Микрофон».</p>
      <img
        alt=""
        src="/chat/instruction/android_chrome_microphone_request_settings.jpg"
        srcSet={`/chat/instruction/android_chrome_microphone_request_settings.jpg 1x,
              /chat/instruction/android_chrome_microphone_request_settings@2x.jpg 2x,
              /chat/instruction/android_chrome_microphone_request_settings@3x.jpg 3x`}
      />
    </li>
    <li>
      <p>
        Проверьте, есть ли сайт lunaro.ru в списке заблокированных. Если есть, нажмите на него и выберите
        «Разрешить».
      </p>
    </li>

    <li>
      <p>Вернитесь на наш сайт на страницу проверки видеосвязи.</p>
      <img
        alt=""
        src="/chat/instruction/lunaro_check_media.jpg"
        srcSet={`/chat/instruction/lunaro_check_media.jpg 1x,
              /chat/instruction/lunaro_check_media@2x.jpg 2x,
              /chat/instruction/lunaro_check_media@3x.jpg 3x`}
      />
    </li>
    <li>Перезагрузите страницу, чтобы настройки вступили в силу.</li>
    <li>Во всплывающем окне нажмите «Разрешить» для камеры и микрофона.</li>
  </ol>,
  }, {
    group: "Компьютер или планшет",
    name: "Google Chrome",
    browser: BrowserNameEnum.Chrome,
    OS: "",
    deviceType: DeviceTypeEnum.Desktop,
    instruction:
  <>
    <p>
      Скопируйте адрес chrome://settings/content/ в строку браузера и нажмите Enter. Вам сразу откроется страница с
      настройками камеры и микрофона.
    </p>
    <p>Если копировать адрес неудобно, откройте настройки камеры и микрофона вручную.</p>
    <ol>
      <li>Нажмите на значок меню (три точки, справа, вверху) вашего Google Chrome и выберите «Настройки».</li>
      <li>
        <p>
          На странице настроек в Главном меню слева нажмите на «Конфиденциальность и безопасность», затем перейдите
          в «Настройки сайтов».
        </p>
        <div className="gif-container">
          <img src="/chat/instruction/gif/chrome_settings.gif" alt="" />
        </div>
      </li>
      <li>
        <span>В подпункте «Камера»:</span>
        <ul>
          <li>выберите нужное устройство из выпадающего списка</li>
          <li>выберите «Разрешить сайтам отправлять запрос на использование камеры»</li>
          <li>найдите и удалите lunaro.ru в разделе «Запретить сайтам использовать камеру»</li>
        </ul>
        <div className="gif-container">
          <img src="/chat/instruction/gif/chrome_access_camera.gif" alt="" />
        </div>
      </li>
      <li>
        <span>В подпункте «Микрофон»:</span>
        <ul>
          <li>выберите устройство из выпадающего списка</li>
          <li>выберите «Разрешить сайтам отправлять запрос на использование микрофона»</li>
          <li>найдите и удалите lunaro.ru в разделе «Запретить сайтам использовать микрофон»</li>
        </ul>
        <div className="gif-container">
          <img src="/chat/instruction/gif/chrome_access_micro.gif" alt="" />
        </div>
      </li>
      <li>Вернитесь на наш сайт на страницу проверки видеосвязи.</li>
      <li>Перезагрузите браузер, чтобы настройки вступили в силу.</li>
      <li>Во всплывающем окне нажмите “Разрешить” для использования камеры и микрофона.</li>
    </ol>
  </>,
  }, {
    group: "Компьютер или планшет",
    name: "Mozilla Firefox",
    browser: BrowserNameEnum.Firefox,
    OS: "",
    deviceType: DeviceTypeEnum.Desktop,
    instruction:
  <>
    <p>
      Скопируйте адрес about:preferences#privacy в строку браузера и нажмите Enter. Вам сразу откроется страница с
      настройками камеры и микрофона.
    </p>
    <p>Если копировать адрес неудобно, откройте настройки камеры и микрофона вручную.</p>

    <ol>
      <li>Нажмите на значок меню (три горизонтальные полоски, справа, вверху) и выберите «Настройки».</li>
      <li>
        <p>Слева в меню выберите «Приватность и защита», прокрутите страницу вниз до раздела «Разрешения».</p>
        <div className="gif-container">
          <img src="/chat/instruction/gif/firefox_private_security.gif" alt="" />
        </div>
      </li>
      <li>
        <p>
          Нажмите на кнопку «Параметры» напротив пункта «Камера». Откроется окно со списком сайтов. Найдите в
          списке сайт lunaro.ru и справа от него в колонке «Статус» кликните на «Разрешить». Нажмите на кнопку
          «Сохранить изменения».
        </p>
        <div className="gif-container">
          <img src="/chat/instruction/gif/firefox_camera_access.gif" alt="" />
        </div>
      </li>
      <li>
        <p>
          Нажмите на кнопку «Параметры» напротив пункта «Микрофон». Найдите в списке сайт lunaro.ru и справа от
          него в колонке «Статус» кликните на «Разрешить». Нажмите на кнопку «Сохранить изменения».
        </p>
        <div className="gif-container">
          <img src="/chat/instruction/gif/firefox_microphone_access.gif" alt="" />
        </div>
      </li>
      <li>Вернитесь на наш сайт на страницу проверки видеосвязи.</li>
      <li>Перезагрузите браузер, чтобы настройки вступили в силу.</li>
      <li>Во всплывающем окне нажмите «Разрешить» для камеры и микрофона.</li>
    </ol>
  </>,
  }, {
    group: "Компьютер или планшет",
    name: "Яндекс.Браузер",
    browser: BrowserNameEnum.Yandex,
    OS: "",
    deviceType: DeviceTypeEnum.Desktop,
    instruction:
  <ol>
    <li>Нажмите на значок меню (три горизонтальные полоски, справа, вверху) и выберите «Настройки».</li>
    <li>
      <p>Выберите слева «Сайты» и прокрутите страницу до выделенной строки «Расширенные настройки сайтов».</p>
      <div className="gif-container">
        <img src="/chat/instruction/gif/YA_browser_settings.gif" alt="" />
      </div>
    </li>
    <li>
      <p>
        В списке разрешений найдите «Доступ к камере» и выберите параметр «Запрашивать разрешение». Ниже в
        «Настройках сайтов» проверьте, находится ли lunaro.ru в колонке «Разрешен». Если наш сайт в колонке
        «Запрещен» — нажмите напротив на кнопку «Удалить».
      </p>
      <div className="gif-container">
        <img src="/chat/instruction/gif/YA_browser_camera_access.gif" alt="" />
      </div>
    </li>
    <li>
      <p>
        В списке разрешений найдите «Доступ к микрофону» и выберите параметр «Запрашивать разрешение». Ниже в
        «Настройках сайтов» проверьте, находится ли lunaro.ru в колонке «Разрешен». Если наш сайт в колонке
        «Запрещен» — нажмите напротив на кнопку «Удалить».
      </p>
      <div className="gif-container">
        <img src="/chat/instruction/gif/YA_browser_microphone_access.gif" alt="" />
      </div>
    </li>
    <li>Перезагрузите браузер, чтобы настройки вступили в силу.</li>
  </ol>,
  },
];
