import React from "react";

import { isBrowser } from "@/utils/env";
import FixMediaAccess from "@components/chat/mediaCheck/FixMediaAccess";

const FixMediaAccessPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return (
    <FixMediaAccess />
  );
};

export default FixMediaAccessPage;
